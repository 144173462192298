body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #434343;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.herobtn,
.herobtn2 {
  position: absolute;
  width: 232px; /* Fixed width for large screens */
  height: 64.25px;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid #fff5f5;
  padding: 14px 38px;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    94.63deg,
    rgba(255, 255, 255, 0.9) 3.17%,
    rgba(255, 255, 255, 0.9) 48.7%,
    rgba(255, 246, 246, 0.9) 97.75%
  );
  color: black;
  transition: 0.3s;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
}

.herobtn:hover,
.herobtn2:hover {
  box-shadow: 2px 2px 3px rgb(85, 81, 81);
  opacity: 0.7;
}

/* Large devices (desktops, 1200px and up) */
@media (min-width: 1400px) {
  .herobtn {
    top: 1138px;
    left: 600px; /* Original left position for large screens */
  }

  .herobtn2 {
    top: 1138px;
    left: 852px; /* Original left position for large screens */
  }
}
@media (min-width: 1200px) {
  .herobtn {
    top: 1138px;
    left: 42%; /* Original left position for large screens */
  }

  .herobtn2 {
    top: 1138px;
    left: 60%; /* Original left position for large screens */
  }
}

/* Medium devices (tablets, 768px to 1199px) */
@media (max-width: 1199px) and (min-width: 768px) {
  .herobtn {
    top: 140%; /* Adjust top for tablet */
    left: 50%; /* Center the button horizontally */
  }

  .herobtn2 {
    top: 150%; /* Adjust top for tablet */
    left: 50%; /* Center the button horizontally */
  }
}

/* Small devices (mobiles, less than 768px) */
@media (max-width: 768px) {
  .herobtn {
    top: 110%; /* Adjust top for small screens */
    left: 50%; /* Center the button horizontally */
    width: 70%; /* Increase width to fit smaller screens */
    font-size: 20px; /* Adjust font size */
  }

  .herobtn2 {
    top: 120%; /* Adjust top for small screens */
    left: 50%; /* Center the button horizontally */
    width: 70%; /* Increase width to fit smaller screens */
    font-size: 20px; /* Adjust font size */
    justify-content: center;
  }
}

/* Extra small devices (smaller phones, less than 480px) */
@media (max-width: 576px) {
  .herobtn {
    top: 95%; /* Further adjust top */
    left: 50%; /* Center the button horizontally */
    width: 80%; /* Max width for very small screens */
    font-size: 16px; /* Reduce font size */
  }

  .herobtn2 {
    top: 105%; /* Further adjust top */
    left: 50%; /* Center the button horizontally */
    width: 80%; /* Max width for very small screens */
    font-size: 16px; /* Reduce font size */
  }
}

/* Extra small devices (smaller phones, less than 480px) */
@media (max-width: 480px) {
  .herobtn {
    top: 90%; /* Further adjust top */
    left: 50%; /* Center the button horizontally */
    width: 80%; /* Max width for very small screens */
    font-size: 16px; /* Reduce font size */
  }

  .herobtn2 {
    top: 100%; /* Further adjust top */
    left: 50%; /* Center the button horizontally */
    width: 80%; /* Max width for very small screens */
    font-size: 16px; /* Reduce font size */
  }
}
/* Default styles for large screens */
.left-side span,
.right-side span {
  font-size: 60px;
}

.left-side img,
.right-side img {
  width: 50px;
  height: 50px;
}

/* Medium screens (768px - 1199px) */
@media (max-width: 1199px) {
  .left-side,
  .right-side {
    left: 0px;
    right: 0px;
    top: 50px;
    gap: 10px;
    flex: 1 1 50%;
  }

  .left-side span,
  .right-side span {
    font-size: 45px;
  }

  .left-side img,
  .right-side img {
    width: 40px;
    height: 40px;
  }
}

/* Small screens (less than 768px) */
@media (max-width: 768px) {
  .left-side {
    position: relative;
    left: 0;
    justify-content: center;
  }

  .right-side {
    position: relative;
    left: 0;
    justify-content: center;
  }

  .left-side span,
  .right-side span {
    font-size: 35px;
  }

  .left-side img,
  .right-side img {
    width: 35px;
    height: 35px;
  }
}

/* Extra small screens (stacking the elements vertically) */
@media (max-width: 480px) {
  .left-side,
  .right-side {
    position: static;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }

  .left-side span,
  .right-side span {
    font-size: 25px;
  }

  .left-side img,
  .right-side img {
    width: 25px;
    height: 25px;
  }
}

/* Base styles for large screens */
.responsive-container {
  width: 80%;
  max-width: 1009px;
  height: auto;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for top adjustments */
}

/* Medium screens (768px - 1199px) */
@media (max-width: 1199px) {
  .responsive-container {
    width: 90%;
    top: 250px;
  }
}

/* Small screens (less than 768px) */
@media (max-width: 768px) {
  .responsive-container {
    width: 95%;
    top: 350px; /* Increase this value to push the image further down */
    margin-top: 50px; /* Additional margin if needed */
  }
}

/* Extra small screens (less than 480px) */
@media (max-width: 480px) {
  .responsive-container {
    width: 100%;
    top: 450px; /* Further increase this value to push the image down */
    margin-top: 100px; /* Additional margin if needed */
  }
}

/* Styles for the stats container */
.stats-container {
  height: 135px;
  display: flex;
  flex-direction: row; /* Default direction */
  justify-content: space-between;
  align-items: center;
  padding: 35px 50px;
  width: 100%; /* Make the width responsive */
  max-width: 1339px;
  gap: 19px;
  border-radius: 15px;
  background: rgba(212, 212, 212, 0.1); /* Semi-transparent background */
  border: 3px solid;
  border-image-source: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.05) 50%
  );
  box-shadow: 0px 0px 3.5px 0px #fefefe;
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
}

/* Styles for each stats item */
.stats-item {
  text-align: center;
  color: #ffffff;
  margin-left: 2rem;
}

/* Styles for the stats row */
.stats {
  height: auto; /* Auto height to fit content */
  display: flex;
  flex-direction: row; /* Default direction */
  justify-content: space-around;
  align-items: center;
  color: #fff;
  padding: 20px 0;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .stats-container {
    flex-direction: column; /* Stack items vertically on small screens */
    padding: 20px; /* Adjust padding for smaller screens */
    position: relative;
    bottom: 180px;
    height: 335px;
  }

  .stats-item {
    margin-left: 0; /* Remove left margin when stacking vertically */
    margin-bottom: 20px; /* Add space between stacked items */
  }

  .stats {
    flex-direction: column; /* Stack items vertically */
    height: auto; /* Adjust height to fit content */
  }
}

.numbers,
.heading {
  margin: 0;
}

.numbers {
  font-size: 34px;
  font-weight: 800;
  line-height: 37.4px;
}

.heading {
  font-size: 24px;
  font-weight: 800;
  line-height: 26.4px;
}

.issue-container {
  margin: 0;
  height: 716px;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(16, 18, 21, 1));
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Base styles for heading */
.issue-heading {
  color: white;
  filter: blur(0.5px);
  font-size: 36px; /* Base font size */
  font-weight: 800;
  margin-bottom: 40px;
  text-align: center; /* Center text */
  position: relative; /* Change to relative for centering */
  left: 50%; /* Center horizontally */
  top: 20%;
  transform: translateX(-50%); /* Adjust for centering */
}

/* Responsive styles */
@media (max-width: 992px) {
  /* Tablets and smaller devices */
  .issue-heading {
    font-size: 30px; /* Adjust font size */
    margin-bottom: 30px; /* Adjust margin */
  }
}

@media (max-width: 768px) {
  /* Mobile devices */
  .issue-heading {
    font-size: 24px; /* Adjust font size */
    margin-bottom: 20px; /* Adjust margin */
  }
}

@media (max-width: 576px) {
  /* Extra small devices */
  .issue-heading {
    font-size: 20px; /* Adjust font size */
    margin-bottom: 15px; /* Adjust margin */
  }
}

.slider-wrapper {
  position: relative;
  top: 30%;
  left: 5%;
  width: 90%;
  /* display: flex; */
  /* justify-content: space-around; */
}

.slider-item {
  position: relative;
  text-align: center;
  padding: 0 15px;
}

.card {
  background-color: white;
  margin: 0 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.3s;
  cursor: pointer;
}

.card-img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .slider-item {
    margin: 0 5px;
  }
}

@media (max-width: 768px) {
  .slider-item {
    margin: 0;
  }
}

.qna {
  font-size: 34px;
  font-weight: 600;
  line-height: 50px;
  text-align: left;
}

.line {
  position: relative;
  left: 150px;
  border: 1px solid gray;
  height: 1px;
  width: 1048px;
  opacity: 0.2;
}

.line-text {
  position: relative;
  width: 696px;
  height: 36px;
  left: 150px;
  font-size: 25px;
  white-space: nowrap;
  margin-bottom: 20px;
}

.line-plus {
  position: relative;
  top: 5px;
  left: 230px;
  width: 30px;
  height: 30px;
  padding: 2.5px 0px 0px 0px;
  cursor: pointer;
}

.navbar-left a {
  text-decoration: none; /* Removes underline from the link */
}

.navbar-left {
  display: flex;
  align-items: center; /* Aligns logo and text vertically */
  gap: 10px; /* Adds space between the logo and text */
  margin-left: 20px;
}

.logo {
  width: 140px;
  height: 110px;
}

.logo-title {
  font-size: 26px; /* Adjust font size as needed */
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin: 0; /* Remove any default margin */
}

.nav-item {
  display: flex;
  justify-content: center; /* Center align the text */
  width: auto; /* Remove fixed width to prevent text wrapping */
  font-weight: 400;
  font-size: 18px;
  height: 20px;
  margin-left: 8px; /* Space between items */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.nav-item:hover {
  text-decoration: underline;
  opacity: 0.7;
}

.nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 20px;
  height: 40px;
  font-size: 18px;
  border: none;
  background-color: #5cb1ff;
  color: #fff;
  margin-left: 16px;
  font-weight: 400;
  transition: 0.3s;
  padding: 20px;
}

.nav-btn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 20px;
  height: 40px;
  font-size: 18px;
  border: none;
  background-color: #5cb1ff;
  color: #fff;
  margin-left: 16px;
  font-weight: 400;
  transition: 0.3s;
  padding: 20px;
}

.nav-btn:hover {
  text-decoration: none;
  opacity: 0.9;
  box-shadow: 2px 2px 3px rgb(85, 81, 81);
}

.social-links {
  width: 161.65px;
  height: 20px;
  position: relative;
  top: 0;
  margin: 0 auto;
  gap: 18px;
  font-size: 25px;
  gap: 18px;
}

.social-links a {
  color: white;
}

.social-links a:hover {
  opacity: 0.8;
}

.footer {
  height: 350px;
  background-color: #212121;
  display: flex;
  justify-content: flex-start;
}

.footer-links a {
  font-weight: 400;
  font-size: 16px;
  line-height: 37px;
  letter-spacing: 0.5px;
  position: relative;
  top: 70px;
  gap: 160px;
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
  color: #fff;
  opacity: 0.5;
}

.footer-links p {
  position: relative;
  top: 30px;
  width: 59px;
  height: 28px;
  cursor: pointer;
}

.about {
  height: 850px;
  position: relative;
  top: 35px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-title {
  margin-top: 80px;
  font-weight: 500;
  font-size: 34px;
  line-height: 37px;
  width: auto;
  height: 37px;
  text-align: center;
}

.about-btn {
  width: 232px;
  height: 64.25px;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid #fff5f5;
  padding: 14px, 38px, 14px, 38px;
  transform: translate(-0%, -60%);
  background-color: white;
  color: black;
  transition: 0.3s;
  font-size: 24px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
}

.about-btn:hover {
  box-shadow: 2px 2px 3px rgb(85, 81, 81);
  opacity: 0.7;
}

.para-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-para {
  width: 1000px;
  position: relative;
  top: 50px;
  left: 150px;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.feeling {
  background-color: rgba(68, 68, 68, 1);
  height: 708px;
  width: 1325px;
  border-radius: 24px;
}

.feeling-para {
  width: 100%;
  margin: 0 auto;
  padding-top: 31px;
  font-size: 40px;
  font-weight: 400;
  line-height: 54.56px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.feeling-img {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.feeling-img .col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.feeling-img img {
  max-width: 100%; /* Ensure the image fits within its container */
  height: auto;
}

.feeling-img-p {
  margin-top: 30px; /* Add spacing between image and text */
  font-weight: 400;
  color: rgba(219, 211, 211, 1);
  font-size: 35px;
  font-weight: 400;
  line-height: 42.36px;
  text-align: center;
}

.feeling-para2 {
  width: 724px;
  height: 34px;
  margin: 100px auto 0; /* Centers horizontally and sets top margin */
  font-size: 25px;
  font-weight: 400;
  line-height: 1.36; /* Adjusted for better readability */
  text-align: center;
  color: rgba(242, 242, 242, 1);
}

.button-container {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 20px; /* Space between buttons */
  width: 100%; /* Ensure the container takes the full width */
}

.feeling-btn {
  width: 343px;
  height: 58.58px;
  padding: 2.83px 19.8px 2.83px 21.41px;
  gap: 10.91px;
  border-radius: 25.86px;
  background: linear-gradient(90deg, #085991 0%, #021a2b 100%);
  font-size: 25.39px;
  font-weight: 400;
  line-height: 30.72px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.feeling-btn-icon {
  width: 23.53px;
  height: 23.53px;
  color: rgba(255, 255, 255, 1);
  margin-right: 10px; /* Adjust space between icon and text */
}

/* Parent container holding the background image */
.background-container {
  background-image: url("../public/media/images/girl-boy-alone.png"); /* Your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 180vh; /* Enough height to cover navbar, hero, and stats */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure sections are spread */
  background-color: black;
}

/* Media Queries */

/* For screens smaller than 1200px (Large desktops) */
@media (max-width: 1199px) {
  .background-container {
    min-height: 180vh; /* Adjust height for large desktops */
  }
}

/* For screens smaller than 992px (Tablets and small desktops) */
@media (max-width: 991px) {
  .background-container {
    min-height: 180vh; /* Adjust height for tablets and small desktops */
  }
}

/* For screens smaller than 768px (Small tablets and mobile devices) */
@media (max-width: 767px) {
  .background-container {
    min-height: 180vh; /* Adjust height for small tablets and mobile devices */
  }
}

/* For screens smaller than 576px (Extra small devices) */
@media (max-width: 575px) {
  .background-container {
    min-height: 160vh; /* Adjust height for extra small devices */
  }
}

/* Transparent sections */
.navbar,
.hero,
.stats {
  background-color: transparent;
}

/* Navbar styling */
.navbar {
  height: 100px;
  border-bottom: 1px solid gray;
}

/* Hero section styling */
.hero {
  height: 500px; /* Adjust height for the hero section */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}

/* Stats section styling */
.stats {
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  padding: 20px 0;
}

/* Base styles for large screens */
.click-away {
  width: 80%; /* Responsive width */
  max-width: 1228px; /* Maximum width */
  height: auto; /* Height adjusts to content */
  position: absolute; /* Absolute positioning */
  top: 130%; /* Position vertically */
  left: 50%; /* Position horizontally */
  transform: translate(-50%, -50%); /* Center the text */
  font-size: 5rem; /* Base font size */
  font-weight: bold;
  line-height: 1.2; /* Line height relative to font size */
  text-align: center;
  color: #fefefe; /* Text color */
  filter: blur(1px);
  margin-bottom: 40px;
}

/* Medium screens (768px - 1199px) */
@media (max-width: 1199px) {
  .click-away {
    font-size: 4rem; /* Adjust font size */
    top: 125%; /* Slight adjustment */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -52%); /* Adjust centering */
  }
}

/* Small screens (768px - 480px) */
@media (max-width: 768px) {
  .click-away {
    font-size: 3rem; /* Further adjust font size */
    top: 95%; /* Slight adjustment */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -54%); /* Adjust centering */
  }
}

/* Extra small screens (less than 480px) */
@media (max-width: 576px) {
  .click-away {
    font-size: 2rem; /* Further adjust font size */
    top: 85%; /* Slight adjustment */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -56%); /* Adjust centering */
  }
}

/* Extra small screens (less than 480px) */
@media (max-width: 480px) {
  .click-away {
    font-size: 2rem; /* Further adjust font size */
    top: 82%; /* Slight adjustment */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -56%); /* Adjust centering */
  }
}

/* General styling */
.about-page {
  text-align: center;
  /* background-color: black; */
}

/* Message text styling */
/* .click-away {
  font-size: 5rem;
  color: white;
  margin-bottom: 40px;
  font-weight: bold;
} */

.about-p {
  font-size: 2.5rem;
  color: white;
  margin-bottom: 20px;
  font-weight: bold;
  position: relative;
  top: 70px;
}

/* Button container styling */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 150px;
}

/* Button styling */
.herobtn,
.herobtn2 {
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 12px;
  font-size: 1.6rem;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.herobtn:hover,
.herobtn2:hover {
  background-color: #f0f0f0;
}

.herobtn img,
.herobtn2 img {
  width: 34px;
  height: 34px;
}

/* Main container with gradient background */
.about {
  background: linear-gradient(180deg, #000000 0%, #434343 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120vh;
  color: white;
  height: 500px;
}

/* Adjusted inner container to prevent overflow */
.about .container {
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent container */
  padding: 40px;
  max-width: 1250px; /* Set a maximum width for large screens */
  width: 100%; /* Ensure it takes full width */
  margin: 0 auto; /* Center the container */
  border-radius: 10px; /* Smooth borders */
  border: 2px solid rgba(255, 255, 255, 0.3); /* Subtle border */
  box-sizing: border-box; /* Includes padding and border in width */
  overflow: hidden; /* Prevent content overflow */
}

/* Title styling */
.about-title {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 5px; /* Shift title upwards */
  letter-spacing: 1px;
}

/* Paragraph container and text */
.para-container {
  margin-bottom: 20px;
  color: #e0e0e0; /* Light gray text */
  height: 650px;
}

/* Paragraphs with adjusted font size and line breaks */
.about-para {
  font-size: 1.6rem; /* Larger font size */
  line-height: 1.5;
  margin-bottom: 40px;
  word-wrap: break-word; /* Ensures long text breaks to next line */
  max-width: 75%; /* Prevents text overflow */
  text-align: justify; /* Justifies text for a cleaner look */
  box-sizing: border-box;
  padding: 0 15px; /* Adds padding to ensure the text doesn’t touch the edges */
}

/* Additional margin and centering adjustment for responsive layout */
@media screen and (max-width: 768px) {
  .about-title {
    font-size: 2rem; /* Adjust title size on smaller screens */
    margin-top: 0; /* No need for negative margin on small screens */
  }

  .about-para {
    font-size: 1.2rem; /* Smaller text for mobile */
    max-width: 100%; /* Allow full width for smaller screens */
  }

  .button-container {
    flex-direction: column; /* Stack buttons on mobile */
  }

  .herobtn,
  .herobtn2 {
    width: 80%;
    margin-bottom: 10px;
  }
}

/* Reviews section background and alignment */
.reviews-section {
  background: linear-gradient(180deg, #000000 0%, #434343 100%);
  /* padding: 50px 0; */
  color: white;
}

/* Review header */
.review-header {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: white;
}

/* Card Styling */
.review-card {
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid gray;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: auto; /* Changed from fixed height to auto */
  width: 100%; /* Changed from fixed width to 100% */
  max-width: 308px; /* Set a max-width to prevent too large cards */
  margin: auto;
  transition: transform 0.2s;
}

.review-card:hover {
  transform: scale(1.05);
}

/* Review image styling */
.review-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin: 20px auto 0 auto;
}

/* Card title (name) styling */
.card-title {
  margin-top: 15px;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
}

/* Card text (review) styling */
.card-text {
  font-size: 1rem;
  padding: 20px;
  color: #e0e0e0;
  text-align: justify;
}

/* Media Queries */
@media (max-width: 992px) {
  .review-header {
    font-size: 2rem; /* Smaller font size on smaller screens */
  }

  .review-consent {
  }
  .review-card {
    width: 100%; /* Full width on smaller screens */
    max-width: none; /* Remove max-width constraint */
  }

  .review-img {
    width: 80px; /* Adjust image size */
    height: 80px; /* Adjust image size */
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .review-header {
    font-size: 2rem; /* Smaller font size on smaller screens */
  }

  .review-card {
    width: 100%; /* Full width on smaller screens */
    max-width: none; /* Remove max-width constraint */
  }

  .review-img {
    width: 80px; /* Adjust image size */
    height: 80px; /* Adjust image size */
  }
}

@media (max-width: 576px) {
  .review-header {
    font-size: 1.5rem; /* Even smaller font size on extra small screens */
  }

  .card-text {
    font-size: 0.9rem; /* Slightly smaller text size */
  }
}

/* Centering the review header */
.review-header {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: white;
  text-align: center; /* Center the heading text */
}

/* Centering the review consent paragraph */
.review-consent {
  width: 100%; /* Make the width 100% to take the full width of its container */
  max-width: 500px; /* Add max-width for larger screens */
  height: 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center; /* Center the text */
  color: rgba(114, 114, 114, 1);
  margin: 40px auto 0 auto; /* Center the element and add margin on top */
}

/* Ensure parent container is centered */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  background-color: #000;
  padding: 20px 0;
}

.logo-footer {
  height: 95px;
  width: 95px;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.logo-title {
  font-size: 24px;
  color: #fff;
}

.social-links a {
  color: #fff;
  text-decoration: none;
}

footer p {
  color: #fff;
  font-size: 1.1rem;
  margin-top: 20px;
}

@media (max-width: 992px) {
  .nav-link {
    display: block;
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    padding: 1rem 0 1rem 3rem;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    width: auto;
    font-weight: 400;
    font-size: 18px;
    height: 20px;
    margin-left: 8px;
    white-space: nowrap;
    margin-top: 1rem;
  }

  .nav-btn {
    margin-left: 5rem;
    margin-top: 2rem;
  }

  .btn-res {
    margin-top: 1rem;
    /* margin-left: 4.6rem; */
    margin-left: 2.7rem;
  }
}

.about-title {
  font-size: 3rem; /* Larger font size for large screens */
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 5px; /* Shift title upwards */
  letter-spacing: 1px;
}

/* Container adjustments for responsiveness */
.para-container {
  margin-bottom: 20px;
  color: #e0e0e0; /* Light gray text */
  height: auto; /* Adjust height to be dynamic */
  padding: 0 15px; /* Add padding to prevent text from touching edges */
}

.about-title {
  font-size: 3rem; /* Larger font size for large screens */
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 5px; /* Shift title upwards */
  letter-spacing: 1px;
}

/* Container adjustments for responsiveness */
.para-container {
  margin-bottom: 20px;
  color: #e0e0e0; /* Light gray text */
  height: auto; /* Adjust height to be dynamic */
  padding: 0 15px; /* Add padding to prevent text from touching edges */
}

/* Paragraph adjustments for responsiveness */
.about-para {
  font-size: 1.6rem; /* Larger font size */
  line-height: 1.5;
  margin-bottom: 40px;
  word-wrap: break-word; /* Ensures long text breaks to next line */
  max-width: 100%; /* Adjust width for responsiveness */
  text-align: justify; /* Justifies text for a cleaner look */
  box-sizing: border-box;
  padding: 0; /* Remove padding if needed */
  position: relative; /* Restore relative positioning */
  left: 5%; /* Remove any left shift */
  top: 0; /* Remove any top shift */
}

/* Media Queries for responsiveness */

/* For tablets */
@media (max-width: 768px) {
  .about-title {
    font-size: 2.5rem; /* Adjust title size */
    margin-top: 20px; /* Adjust margin */
  }

  .about-para {
    font-size: 1.4rem; /* Adjust paragraph font size */
    margin-bottom: 30px; /* Adjust spacing */
    padding: 0 10px; /* Adjust padding for tablets */
  }
}

/* For mobile devices */
@media (max-width: 576px) {
  .about-title {
    font-size: 2rem; /* Adjust title size */
    margin-top: 15px; /* Adjust margin */
  }

  .about-para {
    font-size: 1.2rem; /* Adjust paragraph font size */
    margin-bottom: 20px; /* Adjust spacing */
    padding: 0 10px; /* Adjust padding for mobile */
  }
}

/* For mobile devices */
@media (max-width: 452px) {
  .about-title {
    font-size: 1.75rem; /* Adjust title size */
    margin-top: 15px; /* Adjust margin */
  }

  .about-para {
    font-size: 1.1rem; /* Adjust paragraph font size */
    margin-bottom: 20px; /* Adjust spacing */
    padding: 0 10px; /* Adjust padding for mobile */
  }
}
.container-grid {
  position: relative;
  width: 100%;
  height: 885px; /* Ensure the container has the same height as its children */
}

/* Common styles for both divs */
.radial-div,
.grid-lines {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

/* Radial gradient on the whole div */
.radial-div {
  background-color: #101215; /* Solid black background */
  background-image: radial-gradient(
    circle at top,
    #6980fc,
    #323ca9 40%,
    transparent 70%
  );
  background-size: cover; /* Ensure the background covers the div */
  z-index: 1; /* Lower z-index to keep the grid lines on top */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .radial-div {
    background-image: radial-gradient(
      circle at top,
      #6980fc,
      #323ca9 50%,
      transparent 80%
    );
  }
}

@media (max-width: 992px) {
  .radial-div {
    background-image: radial-gradient(
      circle at top,
      #6980fc,
      #323ca9 60%,
      transparent 90%
    );
  }
}

@media (max-width: 768px) {
  .radial-div {
    background-image: radial-gradient(
      circle at top,
      #6980fc,
      #323ca9 70%,
      transparent 100%
    );
  }
}

@media (max-width: 576px) {
  .radial-div {
    background-image: radial-gradient(
      circle at top,
      #6980fc,
      #323ca9 80%,
      transparent 100%
    );
  }
}

/* Grid lines overlay */
.grid-lines {
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1) 1px,
      transparent 1px
    ),
    /* Vertical grid lines */
      linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px); /* Horizontal grid lines */
  background-size: 120px 120px;
  z-index: 2; /* Higher z-index to make sure the grid lines appear on top of the radial gradient */
  pointer-events: none; /* Prevent grid-lines from blocking interactions */
}

.container-gradient {
  /* position: relative; */
  width: 100%;
  /* Ensure the container has the same height as its children */
}
.gradient-aboutus {
  width: 100%; /* Full width of the container */
  height: 1000px; /* Height as per provided dimension */
  position: relative; /* Positioning for placing the radial gradient */

  /* Solid black background */
  background-color: #000; /* Black background */

  /* Multiple radial gradients */
  background-image: radial-gradient(
    circle at 5% 50%,
    #9412fb -90%,
    transparent 25%
  ); /* First radial gradient */

  background-size: cover; /* Ensure it fills the container */
  background-repeat: no-repeat; /* Prevent repetition of the gradients */
}

.gradient-aboutus::after {
  content: "";
  position: absolute;
  top: -150px; /* Extend the gradient into the top of the container */
  right: 0; /* Position it towards the right side */
  width: 100%; /* Control the size of the gradient */
  height: 100%; /* Height to create a circular effect */

  background: radial-gradient(
    circle at 50% 50%,
    #00aaff -100%,
    transparent 60%
  ); /* Blue gradient */
  z-index: 2; /* Ensure this overlaps other content */
  border-radius: 50%; /* Make it circular */
  pointer-events: none; /* Ensure it doesn’t interfere with user interactions */
}

/* Reviews Section */
.gradient-reviews {
  width: 100%;
  height: 700px;
  position: relative;
  background-color: #000;

  /* Radial gradients */
  background-image: radial-gradient(
    circle at 5% 50%,
    #e6c51a -90%,
    transparent 25%
  ); /* Yellow gradient */

  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
}

/* Green gradient extending into footer with circular curve */
.gradient-reviews::after {
  content: "";
  position: absolute;
  bottom: -150px; /* Adjust this to extend gradient into footer */
  right: 0; /* Move it slightly to the right for better centering */
  width: 100%; /* Control the size of the gradient to make it larger */
  height: 100%; /* Larger height to create a circular effect */

  background: radial-gradient(
    circle at 50% 50%,
    #08cb62 -100%,
    transparent 50%
  );
  z-index: 2;
  border-radius: 50%; /* Make it circular */
}

/* Responsive Styles */
@media (max-width: 992px) {
  .gradient-reviews::after {
    content: "";
    position: absolute;
    bottom: -150px; /* Adjust this to extend gradient into footer */
    right: 0; /* Move it slightly to the right for better centering */
    width: 100%; /* Control the size of the gradient to make it larger */
    height: 500px; /* Larger height to create a circular effect */

    background: radial-gradient(
      circle at 50% 50%,
      #08cb62 -90%,
      transparent 65%
    );
    z-index: 2;
    border-radius: 50%; /* Make it circular */
  }

  .gradient-aboutus::after {
    content: "";
    position: absolute;
    top: -150px; /* Extend the gradient into the top of the container */
    right: 0; /* Position it towards the right side */
    width: 100%; /* Control the size of the gradient */
    height: 500px; /* Height to create a circular effect */

    background: radial-gradient(
      circle at 50% 50%,
      #00aaff -90%,
      transparent 60%
    ); /* Blue gradient */
    z-index: 2; /* Ensure this overlaps other content */
    border-radius: 50%; /* Make it circular */
    pointer-events: none; /* Ensure it doesn’t interfere with user interactions */
  }
}

.navs-btn {
  position: relative;
  top: 30px;
  width: 200px;
  height: 60.25px;
  gap: 10px;
  border-radius: 10px;
  border: 2px solid #fff5f5;
  padding: 14px, 38px, 14px, 38px;
  transform: translate(-0%, -60%);
  background-color: white;
  color: black;
  transition: 0.3s;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  text-align: center;
  margin-right: 15px;
}

.navs-btn:hover {
  box-shadow: 2px 2px 3px rgb(85, 81, 81);
  opacity: 0.7;
}

.navs-btn img {
  height: 30px;
  width: 30px;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 1000;
}

.navbar-visible {
  top: 0;
}

.navbar-hidden {
  top: -100px; /* Hide the navbar by moving it above the viewport */
}

.background-container {
  padding-top: 80px; /* Add padding so that the content doesn't overlap the navbar */
}

/* Add this to your CSS file */

.navbar-collapse {
  transition: height 0.3s ease;
}

.navbar-collapse.show {
  display: block; /* Ensures the menu is visible when open */
}

/* Base styles for buttons */
.navs-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px 24px; /* Base padding */
  font-size: 22px; /* Base font size */
  width: 196px; /* Base width for buttons */
  transition: margin-top 0.3s ease, padding 0.3s ease, font-size 0.3s ease;
}

/* Responsive styles for smaller screens */
@media (max-width: 992px) {
  /* Tablets and smaller devices */
  .navs-btn {
    padding: 10px 20px; /* Adjust padding */
    font-size: 14px; /* Adjust font size */
    width: 180px; /* Adjust width */
  }
}

@media (max-width: 768px) {
  /* Mobile devices */
  .navs-btn {
    padding: 8px 16px; /* Adjust padding */
    font-size: 12px; /* Adjust font size */
    width: 160px; /* Adjust width */
  }
}

@media (max-width: 576px) {
  /* Extra small devices */
  .navs-btn {
    padding: 6px 12px; /* Adjust padding */
    font-size: 10px; /* Adjust font size */
    width: 140px; /* Adjust width */
  }
}

/* Navbar Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #333;
  color: white;
  width: 100%; /* Ensure it takes the full width of the screen */
  box-sizing: border-box; /* Include padding in the element's total width */
}

.navbar-toggler {
  display: none;
  background-color: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  color: white;
}

/* Responsive Styles */
@media (max-width: 992px) {
  .navbar-nav {
    display: none;
  }

  .navbar-toggler {
    display: block;
    margin-left: auto; /* Ensures the toggler stays on the right */
  }

  .navbar-toggler-icon {
    font-size: 24px; /* Adjust the size of the icon */
    width: 30px;
    height: 30px;
  }

  .collapse.show .navbar-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #434343;
    border-radius: 5px;
    padding: 2rem;
  }
}

@media (max-width: 576px) {
  .navbar {
    padding: 10px;
  }

  .navbar-toggler {
    display: block;
    margin-right: 0; /* Remove any extra margin */
    padding: 8px;
  }

  .navbar-toggler-icon {
    font-size: 1.5rem;
    width: 30px;
    height: 30px;
  }

  .navbar-nav {
    width: 100%;
    padding: 10px;
  }

  .navbar-nav .nav-item {
    margin-bottom: 15px;
  }
}

/* Media query for tablets and smaller screens */
@media (max-width: 1080px) {
  .about {
    max-width: 95%; /* Adjust max-width to 90% of the viewport width */
  }
}

/* Media query for mobile devices */
@media (max-width: 988px) {
  .about {
    max-width: 90%; /* Adjust max-width to 95% of the viewport width */
  }
}

/* Media query for smaller screens */
@media (max-width: 1132px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 90%;
  }
}

@media (max-width: 1004px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 85%;
  }
  .about-para {
    font-size: 1.3rem;
    position: relative;
    left: 10%;
  }
  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}

/* Media query for smaller screens */
@media (max-width: 988px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 75%;
  }

  .about-para {
    font-size: 1.1rem;
    position: relative;
    left: 15%;
  }
  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
/* Media query for smaller screens */
@media (max-width: 890px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 70%;
  }

  .about-para {
    font-size: 1rem;
    position: relative;
    left: 20%;
  }
  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
/* Media query for smaller screens */
@media (max-width: 835px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 65%;
  }

  .about-para {
    font-size: 0.8rem;
    position: relative;
    left: 30%;

    /* Word breaking */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Ensure long words break within container */
    hyphens: auto; /* Optional: Adds hyphens where words are broken */
  }

  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
/* Media query for smaller screens */
@media (max-width: 776px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 55%;
  }

  .about-para {
    font-size: 0.6rem;
    position: relative;
    left: 45%;

    /* Word breaking */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Ensure long words break within container */
    hyphens: auto; /* Optional: Adds hyphens where words are broken */
  }

  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
/* Media query for smaller screens */
@media (max-width: 618px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 30%;
  }

  .about-para {
    font-size: 0.6rem;
    position: relative;
    left: 130%;

    /* Word breaking */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Ensure long words break within container */
    hyphens: auto; /* Optional: Adds hyphens where words are broken */
  }

  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
/* Media query for smaller screens */
@media (max-width: 305px) {
  .para-container {
    /* Adjust styles for the container if needed */
    width: 20%;
  }

  .about-para {
    font-size: 0.6rem;
    position: relative;
    left: 235%;

    /* Word breaking */
    word-break: break-word; /* Break long words */
    overflow-wrap: break-word; /* Ensure long words break within container */
    hyphens: auto; /* Optional: Adds hyphens where words are broken */
  }

  .about {
    height: 90%;
    position: relative;
    left: 5%;
  }
}
@media (max-width: 992px) {
  .gradient-reviews {
    height: 800px;
  }
}

@media (max-width: 768px) {
  .gradient-reviews {
    height: 1250px;
  }
}

@media (max-width: 453px) {
  .gradient-reviews {
    height: 1450px;
  }
}

@media (max-width: 333px) {
  .gradient-reviews {
    height: 1850px;
  }
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.form input {
  height: 50px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 5px;
}

.form :last-child {
  width: 30%;
  background-color: green;
}

.button:hover {
  background-color: #00aaff;
  color: black;
}

@media (min-width: "1000px") {
  .gradient-aboutus::after {
    content: "";
    position: absolute;
    top: -150px;
    right: 0;
    width: 100%;
    height: 500px;
    background: radial-gradient(
      circle at 50% 50%,
      #00aaff -90%,
      transparent 60%
    );
    z-index: 2;
    border-radius: 50%;
    pointer-events: none;
  }
  .gradient-reviews::after {
    content: "";
    position: absolute;
    bottom: -150px;
    right: 0;
    width: 100%;
    height: 500px;
    background: radial-gradient(
      circle at 50% 50%,
      #08cb62 -90%,
      transparent 65%
    );
    z-index: 2;
    border-radius: 50%;
  }
}

.navbar-toggler-icon {
  background-color: white;
  border-radius: 5px;
}
